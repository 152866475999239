.b-inline {
  display: inline-block;
}

.tableBtn {
  font-size: 20px;
}

.el-table .cell {
  font-family: 'proxima-nova', sans-serif;
  font-size: 15px;
  white-space: nowrap !important;
  /* Запрещаем перенос строк */
  overflow: hidden;
  /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis;

  /* Добавляем многоточие */
  &:hover {
    text-overflow: clip;
    overflow: auto;
  }

  transition: font-size 0.3s ease-in-out;
}

.el-table {
  .noHiddenText {
    overflow: auto;
    text-overflow: clip;
  }
}

.v-select .vs__dropdown-toggle {
  height: 42px;
  overflow: hidden;
  border: 1px solid $blured-input-color;
  border-radius: $border-radius-base;
}
.vs--disabled .vs__clear, .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__open-indicator, .vs--disabled .vs__search, .vs--disabled .vs__selected {
  background-color: #fff;
}
.selectPerPage {
  width: 65px;
}

.perPageText {
  font-size: 14px;
  font-weight: 400;
  color: #9A9A9A;
  min-width: 230px;
  text-align: right;
}

.vs__dropdown-menu {
  margin-top: 12px;
  border-top: 1px solid rgba(60, 60, 60, .26);
  border-radius: $border-radius-extreme;
}

.vs--open .vs__dropdown-toggle {
  border-bottom-color: rgba(60, 60, 60, .26);
  border-bottom-left-radius: $border-radius-extreme;
  border-bottom-right-radius: $border-radius-extreme;
  padding-top: 4px;
}

.el-table__body>tbody>tr>td {
  border-bottom: none;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  // @media(min-width: 1000px) {
  //     padding: 22px 0 20px 0;
  // }
}

.el-table,
.el-table__expanded-cell {
  background-color: rgba(0, 0, 0, 0) !important;
}

.el-table::before,
.el-table__fixed-right::before {
  display: none;
}

.el-table .el-table__header>thead>tr {
  background: transparent;
}

.el-table__body>tbody>tr {
  background: #ffffff;
}

.el-table th.is-leaf {
  border-bottom: none !important;
  background-color: transparent;
}

.card {
  border: none;
}

.el-table__row--level-1 {

  .el-table_1_column_1,
  td {
    background: #fafafa !important;
  }
}

.el-table__body,
.el-table__footer,
.el-table__header {
  border-spacing: 0 12px;
}
input.el-input__inner {
	border-radius: 10px;
  min-height: 42px !important;
  &:focus, &:active, &.is-active {
    border-color: $default-input-color;
  }
}

// TABS //

.el-tabs__nav-wrap.is-top::after {
  bottom: 78px;
  background-color: rgba($color: #0B2D74, $alpha: .1);
  height: 1px;
}
.el-tabs__active-bar {
  display: none;
}
.el-tabs__item.is-top {
  margin-top: 40px;
  text-transform: uppercase;
  color: #2c2c2c;
  font-weight: 700;
  font-size: 16px;
  &:hover, &:active, &.is-active {
    color: $active-title-color;
  }
}

.swal2-container {
  z-index: 9999 !important;
}

.joke {
  div {
    animation: rotate 20s infinite ease-in-out;
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.hover-row td.errortable,
td.errortable {
  background-color: $orange-navbar;
  color: white;
}
.hover-row {
  td.errortable {
    background-color: $orange-navbar !important;
    color: white;
  }
}
.hover-row {
  cursor: default;
}
.hover-row td {
  background-color: #ffffff !important;

  .cell {
    font-size: 16px;
  }
}
.el-table td {
  border: 1px solid transparent !important;
}
.card .card-header {
  background: transparent;
  padding: 45px 20px 50px 15px;
  font-family: 'proxima-nova';
  letter-spacing: 3px;
  font-weight: 700;

  .card-title {
    font-family: inherit;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 26px;
    max-width: 500px;
  }
}
.langbtn {
  cursor: pointer;
}
.form-check {
  padding-top: 6px;
}
.label_main {
  width: 100%;
  padding-top: 3px;
  padding-right: 39px;
  .inpL {
    display: none;
  }
  .close {
    position: absolute;
    right: -53px;
    top: 1px;
  }
  .def_file {
    text-transform: initial !important;
  }
}
.nav-item:hover {
  .nohover {
    a {
      background: transparent !important;
    }
  }
  .nohover:hover {
    a {
      color: $login-color !important;
    }
  }
  .active {
    a {
      color: $login-color !important;
    }
  }
}
.selectRow {
  td {
    background-color: #def2ff !important;
  }
}

.codeExtend {
  overflow-y: scroll;
}

.infoSize {
  .cell {
    height: 100%;
    overflow-x: hidden !important;
    text-overflow: clip !important;
    word-break: keep-all !important;
    white-space: break-spaces !important;
    display: block;
  }
}

.smallBtn {
  min-width: 100%;
}

.noTitle {
  padding-top: 48px;
}

.dopInfo {
  font-weight: 400;
}

.smallTooltip {
  position: relative;

  .smallTooltipbody {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
    .smallTooltipbody {
      display: block;
    }
  }
}

.noHidden {
  .cell {
    overflow: visible !important;
  }
}

.simpleRule {
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  margin-bottom: 31px;
  padding: 12px;

  h4 {
    margin: 0px;
  }
}

.plusLink i {
  color: #87CB16
}

@import './del.scss';

.muliSelectBlock {
  .vs__dropdown-toggle {
    font-size: 12px;
    height: inherit;
    min-height: 42px;
 }
}


.noCapital { .sidebar-normal {
  text-transform: initial;  }
}

.el-table__expand-column .cell .el-table__expand-icon {
  visibility: hidden;
}

.expandbtn {
  background-color: #56a973;
  width: 40px;
  height: 40px;
  position: relative;
  right: 6px;
  top: -6px;
  border-radius: 10px;
}

.addBlockCompany {
  display: flex;
  gap: 10px;
  height: 70px;
  align-items: flex-end;
}

.blockOrg{
  width: 200px;
  height: 70px;
}
.blockBtns{
  display: flex;
  gap: 10px;
}

.blockTokenGen {
  display: flex;
  position: relative;
  z-index: 99;
  height: 42px;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.tokenInput {
  width: 600px !important;
}
